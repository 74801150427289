/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/* Importerar script */

import './plugins/owl-slider/owl.carousel.min.js';
import './plugins/validate/jquery.validate.js';
import './plugins/pickadate/picker.js';
import './plugins/pickadate/picker.date.js';

(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        //*********************************************************************
        // Kör script på startsidan.
        //*********************************************************************
        'home': {
            init: function () {
                $(document).ready(function () {

                    if ($('#closeBanner')[0]) {
                        $('#closeBanner').on('click', function () {
                            $('.info-banner').remove();
                            let headerHeight = $('#header').innerHeight();
                            $('body').css('margin-top', headerHeight);

                            if (window.innerWidth <= 1036) {

                                let priserHeight = $('.priser_storningar').innerHeight();
                                let totalHeight = headerHeight + priserHeight;
                                $('body').css('margin-top', totalHeight);

                            }
                        });
                    }

                    // START SLIDER
                    var n = $("li.item").length;
                    if (n > 1) { var true_false = true; } else { var true_false = false; }
                    $('#home-slider').owlCarousel({
                        loop: true_false,
                        animateOut: 'fadeOut',
                        mouseDrag: false,
                        touchDrag: false,
                        // nav:true,
                        autoplay: true,
                        autoplayTimeout: 6000,
                        smartSpeed: 800,
                        autoplayHoverPause: false,
                        dots: true_false,
                        responsive: {
                            0: { items: 1 },
                            600: { items: 1 },
                            1000: { items: 1 }
                        }
                    });

                    // HÄMTA DRIFTINFORMATION
                    var driftData = ' https://ftp.mirakel.nu/kristinehamn/';
                    new mirakel.drift.News({
                        target: '#aktuellDriftstatus',
                        url: driftData,
                        limit: 1,
                        autoRefresh: 0,
                        version: 3
                    });

                    // var adStatus     = $('#aktuellDriftstatus');
                    // var adFallback   = $('.drifttitle');
                    // if(adStatus.html() === ''){
                    //     adFallback.show();
                    // }

                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },


        //*********************************************************************
        // Kör script på sidor med templaten Standardmall
        //*********************************************************************
        'page_template_default': {
            init: function () {
                $(document).ready(function () {

                    $(document).on('click', 'a[href^="#"]', function (e) {
                        var id = $(this).attr('href');
                        var $id = $(id);
                        if ($id.length === 0) {
                            return;
                        } else {
                            e.preventDefault();
                            var pos = $(id).offset().top - 90;
                            $('body, html').animate({ scrollTop: pos });
                            $($id).addClass('scrolled_to');
                            setTimeout(function () {
                                $('.scrolled_to .paragraph_start').addClass("active");
                                $('.scrolled_to .hidden_content').addClass("active");
                                $('.scrolled_to .hidden_content').slideDown();
                            }, 200);
                        }
                    });

                    // Byt pristabell för fast pris
                    var change = $('.change');
                    change.on('click', function () {
                        tab = $(this).attr('data-tab');
                        change.removeClass('active');
                        change.prop("disabled", false);
                        $('.pricerange').hide();

                        $("[data-tab='" + tab + "']").addClass('active');
                        $("[data-tab='" + tab + "']").prop("disabled", true);
                        $("[data-range='" + tab + "']").show();

                        $(".hid_langd").val(tab + 'ar');
                    });
                    // Toggla prisuppdelning i kWh
                    $('.kwh_part_toggle').on('click', function () {
                        kwhtoggle = $(this).attr('data-kwhtoggle');
                        $(this).toggleClass('open');
                        $("[data-kwh='" + kwhtoggle + "']").slideToggle(300);
                    });
                    // Toggla jämnförpris
                    $('.jfp_part_toggle').on('click', function () {
                        jfptoggle = $(this).attr('data-jfptoggle');
                        $(this).toggleClass('open');
                        $("[data-jfp='" + jfptoggle + "']").slideToggle(300);
                    });

                    function reinitialise() {
                        var kwhbtn = $('.kwh_part_toggle');
                        var jfpbtn = $('.jfp_part_toggle');
                        // Toggla prisuppdelning i kWh
                        kwhbtn.on('click', function () {
                            kwhtoggle = $(this).attr('data-kwhtoggle');
                            $(this).toggleClass('open');
                            $("[data-kwh='" + kwhtoggle + "']").slideToggle(300);
                        });
                        // Toggla jämnförpris
                        jfpbtn.on('click', function () {
                            jfptoggle = $(this).attr('data-jfptoggle');
                            $(this).toggleClass('open');
                            $("[data-jfp='" + jfptoggle + "']").slideToggle(300);
                        });

                        if ($(window).width() <= 768) { /*Fönsterbredden är mindre än */
                            var viewskalk = $('.sidebar_kakyl .view-numbers');
                            var hideskalk = $('.part_holder');
                            viewskalk.on('click', function () {
                                hideskalk.slideUp(300);
                                kwhbtn.removeClass('open');
                                jfpbtn.removeClass('open');
                            });
                        }

                    }

                    // Ändra utseende på radiobutton om den är selected
                    var radiosel = $('.input_radio');
                    var rvissel = $('.rvis');
                    var radio = '.input_radio';
                    var rvis = '.rvis';
                    var radioremove = $('.radio_opt .rvis');

                    radiosel.on('click', function () {
                        var selector = $(this).parent().find(radio).attr('name');

                        radior(selector);

                        if ($(this).prop('checked')) {
                            $(this).parent().find(rvis).addClass('active');
                        } else {
                            $(this).parent().find(rvis).removeClass('active');
                        }
                    });
                    rvissel.on('click', function () {
                        var selector = $(this).parent().find(radio).attr('name');

                        radior(selector);

                        $(this).parent().find(radio).prop("checked", true);
                        $(this).parent().find(rvis).addClass('active');
                    });

                    function radior(selector) {
                        $('.' + selector).parent().find(rvis).removeClass('active');
                    };

                    // Checkboxes
                    var checksel = $('.input_check');
                    var checkclass = '.input_check';
                    var checker = $('.chhold');
                    var check = '.chhold';

                    checksel.on('click', function () {
                        if ($(this).prop('checked')) {
                            $(this).parent().find(check).addClass('active');
                        } else {
                            $(this).parent().find(check).removeClass('active');
                        }
                    });

                    checker.on('click', function () {
                        if ($(this).parent().find(checkclass).prop('checked')) {
                            $(this).removeClass('active');
                            $(this).parent().find(checkclass).prop("checked", false);
                        } else {
                            $(this).addClass('active');
                            $(this).parent().find(checkclass).prop("checked", true);
                        }
                    });

                    // Funktion för att kolla vilken pristabell som används
                    //var fields = $('.avtalstyp, .upskattad_anv');
                    // fields.change(function(){
                    //     var aval = atyp.val();
                    //     var uval = uskatt.val();
                    //
                    //     console.log(aval);
                    //     console.log(uval);
                    //
                    //     place.hide();
                    //     status.toggleClass('show');
                    //
                    //     pristabell(aval, uval);
                    // });

                    var atyp = $('.avtalstyp');
                    var uskatt = $('.upskattad_anv');
                    var lstart = $('.lev_start');
                    var status = $('.status');
                    var place = $('.valplace');
                    var aval = '';
                    var uval = '';
                    var lval = '';

                    atyp.change(function () {
                        aval = atyp.val();
                        merger();
                    });
                    // uskatt.keyup(function(){
                    //     uval = uskatt.val();
                    //     merger();
                    // });
                    lstart.change(function () {
                        lval = lstart.val();
                        merger();
                    });

                    function merger() {
                        if (aval !== '') {
                            place.hide();
                            status.toggleClass('show');
                            pristabell(aval, uval, lval);
                        }
                    }

                    function pristabell(aval, uval, lval) {
                        //console.log(aval);
                        //console.log(uval);
                        var rand = Math.random();
                        //console.log(rand);
                        $.ajax({
                            url: '/83759_wp-content/themes/kristinehamnsenergi/templates/parts/sidebar_prisraknare.php',
                            method: 'GET',
                            data: {
                                typ: aval,
                                beraknat: uval,
                                levstart: lval,
                                rand: rand
                            },
                            success: function (output) {
                                $('.result').html(output);
                                status.toggleClass('show');
                                reinitialise();
                            },
                        });
                    };

                    // CUSTM GET HASH FUNCTION
                    $.extend({
                        getUrlVars: function () {
                            var vars = [], hash;
                            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
                            for (var i = 0; i < hashes.length; i++) {
                                hash = hashes[i].split('=');
                                vars.push(hash[0]);
                                vars[hash[0]] = hash[1];
                            }
                            return vars;
                        },
                        getUrlVar: function (name) {
                            return $.getUrlVars()[name];
                        }
                    });
                    if ($.getUrlVar("avtal") != null) {
                        var atyp = $('.avtalstyp');
                        var status = $('.status');
                        var lstart = $('.lev_start');
                        var aval = '';
                        var lval = '';

                        aval = atyp.val();
                        lval = lstart.val();

                        status.toggleClass('show');
                        pristabell(aval, uval, lval);
                    }


                    // CUSTM notEquals FUNCTION
                    jQuery.validator.addMethod("notEqual", function (value, element, param) {
                        return this.optional(element) || value != param;
                    }, "Please specify a different (non-default) value");

                    // VALIDERA AVTAL
                    (function ($, W, D) {
                        var JQUERY4U = {};
                        JQUERY4U.UTIL = {
                            setupFormValidation: function () {
                                $("#avtal_form").validate({
                                    rules: {
                                        leveransstart: {
                                            required: true,
                                        },
                                        avtalstyp: {
                                            required: true,
                                            notEqual: "na",
                                        },
                                        kundtyp: {
                                            required: true,
                                        },
                                        personnr: {
                                            required: true,
                                            maxlength: 12,
                                            minlength: 12
                                        },
                                        fornamn: {
                                            required: true,
                                        },
                                        efternamn: {
                                            required: true,
                                        },
                                        adress: {
                                            required: true,
                                        },
                                        pnummer: {
                                            required: true,
                                            maxlength: 6,
                                            minlength: 5
                                        },
                                        postadress: {
                                            required: true,
                                        },
                                        epost: {
                                            required: true,
                                            email: true,
                                        },
                                        telnr: {
                                            required: true,
                                        },
                                        boform: {
                                            required: true,
                                        },
                                        anladdr: {
                                            required: true,
                                        },
                                        anlgid: {
                                            required: false,
                                            // maxlength: 18,
                                            // minlength: 18
                                        },
                                        omradeid: {
                                            required: false,
                                        },
                                        lghnr: {
                                            required: false,
                                        },
                                        berarsanvan: {
                                            required: false,
                                        },
                                        godkann: {
                                            required: true,
                                        },
                                    },
                                    messages: {
                                        leveransstart: 'Välj startdatum för leverans',
                                        avtalstyp: 'Välj avtalsform',
                                        kundtyp: 'Välj kundtyp',
                                        personnr: 'Fyll i ditt personnummer',
                                        fornamn: 'Fyll i ditt förnamn',
                                        efternamn: 'Fyll i ditt efternamn',
                                        adress: 'Fyll i din adress',
                                        pnummer: 'Fyll i ditt postnummer',
                                        postadress: 'Fyll i din postort',
                                        epost: 'Fyll i din e-post',
                                        telnr: 'Fyll i ditt telefonnummer',
                                        boform: 'Välj boendeform',
                                        anladdr: 'Fyll i din anläggningsadress',
                                        anlgid: 'Fyll i ditt anläggnings-id',
                                        omradeid: 'Fyll i ditt områdes-id',
                                        lghnr: '',
                                        berarsanvan: '',
                                        godkann: 'Du måste godkänna villkoren',
                                    },
                                    submitHandler: function (form) {
                                        checkSubmit(form);
                                    }
                                });
                            }
                        }
                        $(D).ready(function ($) {
                            JQUERY4U.UTIL.setupFormValidation();
                        });
                    })(jQuery, window, document);

                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },

        //*********************************************************************
        // Kör script på sidor med classen teckna-elavtal
        //*********************************************************************
        'teckna_elavtal': {
            init: function () {
                $(document).ready(function () {

                    // Initiera pickadate för val av leveransstart
                    var lsPickerInput = $('.lev_start').pickadate({
                        firstDay: 1,
                        format: 'yyyy-mm-dd',

                        // Översättningar
                        monthsFull: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'],
                        monthsShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
                        weekdaysFull: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'],
                        weekdaysShort: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'],

                        // Knappar
                        today: 'Idag',
                        clear: 'Rensa',
                        close: 'Stäng',

                        // Tillgänglighet
                        labelMonthNext: 'Nästa månad',
                        labelMonthPrev: 'Tidigare månad',
                        labelMonthSelect: 'Välj månad',
                        labelYearSelect: 'Välj år',
                    });

                    var lsPicker = lsPickerInput.pickadate('picker');
                    lsPicker.set('select', new Date());
                    lsPicker.set('min', true);

                    // Sticky section i sidebaren
                    var skalk = $('.sidebar_kakyl');
                    var viewskalk = $('.sidebar_kakyl .view-numbers');
                    var hideskalk = $('.part_holder');

                    if ($(window).width() > 768) { // Fönsterbredden är större än
                        $(window).scroll(function () {
                            if ($(this).scrollTop() > 690) {
                                skalk.addClass('sticky');
                            } else {
                                skalk.removeClass('sticky');
                            }
                        });
                    } else {
                        skalk.insertAfter('#sidebar');
                        skalk.addClass('hiddenbottom');

                        viewskalk.on('click', function () {
                            skalk.toggleClass('hiddenbottom');
                        });
                    }


                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },

        //*********************************************************************
        // Kör script på sidor med classen intresseanmalan
        //*********************************************************************
        'intresseanmalan': {
            init: function () {
                $(document).ready(function () {

                    $('.loper_ut').pickadate({
                        firstDay: 1,
                        format: 'yyyy-mm-dd',

                        // Översättningar
                        monthsFull: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'],
                        monthsShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
                        weekdaysFull: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'],
                        weekdaysShort: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'],

                        // Knappar
                        today: 'Idag',
                        clear: 'Rensa',
                        close: 'Stäng',

                        // Tillgänglighet
                        labelMonthNext: 'Nästa månad',
                        labelMonthPrev: 'Tidigare månad',
                        labelMonthSelect: 'Välj månad',
                        labelYearSelect: 'Välj år',
                    });

                    // Ändra tidigast valbara dag till dagens datum +20
                    var $input_ls = $('.loper_ut').pickadate();
                    var lu_picker = $input_ls.pickadate('picker');
                    lu_picker.set('min', +1);

                    // VALIDERA AVTAL
                    (function ($, W, D) {
                        var JQUERY4U = {};
                        JQUERY4U.UTIL = {
                            setupFormValidation: function () {
                                $("#intresse_form").validate({
                                    rules: {
                                        fornamn: {
                                            required: true,
                                        },
                                        efternamn: {
                                            required: true,
                                        },
                                        adress: {
                                            required: true,
                                        },
                                        postnummer: {
                                            required: true,
                                            maxlength: 6,
                                            minlength: 5
                                        },
                                        postort: {
                                            required: true,
                                        },
                                        loperut: {
                                            required: false,
                                        },
                                        tel: {
                                            required: true,
                                        },
                                        email: {
                                            required: true,
                                            email: true,
                                        },
                                        gdpr: {
                                            required: true,
                                        },
                                    },
                                    messages: {
                                        fornamn: 'Fyll i ditt förnamn',
                                        efternamn: 'Fyll i ditt efternamn',
                                        adress: 'Fyll i din postadress',
                                        postnummer: 'Fyll i ditt postnummer',
                                        postort: 'Fyll i din postort',
                                        tel: 'Fyll i ditt telefonnummer',
                                        email: 'Fyll i din e-post',
                                        gdpr: 'Du måste godkänna villkoren',
                                    },
                                    submitHandler: function (form) {
                                        checkSubmit(form);
                                    }
                                });
                            }
                        }
                        $(D).ready(function ($) {
                            JQUERY4U.UTIL.setupFormValidation();
                        });
                    })(jQuery, window, document);

                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },

        //*********************************************************************
        // Kör script på sidor med templaten Kalkylator
        //*********************************************************************
        'page_template_page_teknaavtal': {
            init: function () {
                $(document).ready(function () {

                    var change = $('.change');
                    change.on('click', function () {
                        tab = $(this).attr('data-tab');
                        change.removeClass('active');
                        change.prop("disabled", false);
                        $('.pricerange').hide();

                        $("[data-tab='" + tab + "']").addClass('active');
                        $("[data-tab='" + tab + "']").prop("disabled", true);
                        $("[data-range='" + tab + "']").show();

                        $(".hid_langd").val(tab + 'ar');
                    });

                    $('.kwh_part_toggle').on('click', function () {
                        kwhtoggle = $(this).attr('data-kwhtoggle');
                        $(this).toggleClass('open');
                        $("[data-kwh='" + kwhtoggle + "']").slideToggle(300);
                    });

                    $('.jfp_part_toggle').on('click', function () {
                        jfptoggle = $(this).attr('data-jfptoggle');
                        $(this).toggleClass('open');
                        $("[data-jfp='" + jfptoggle + "']").slideToggle(300);
                    });

                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },

        //*********************************************************************
        // Kör script på sidor med templaten FAQ
        //*********************************************************************
        'page_template_page_faq': {
            init: function () {
                $(document).ready(function () {

                    var tglr = $('.top');

                    function toggleQuestion(event) {
                        if (event.type === 'click' || (event.type === 'keydown' && (event.key === ' ' || event.key === 'Space'))) {
                            event.preventDefault(); // Förhindra scrollning vid "space"-tryck

                            var question = $(this).attr('data-question');

                            $("[data-answer='" + question + "']").slideToggle(300);
                            $("[data-icon='" + question + "']").toggleClass('open');
                            $(this).attr('aria-expanded', function (i, attr) {
                                return attr === 'true' ? 'false' : 'true';
                            });
                        }
                    }

                    // Lägg till event-lyssnare för klick och tangenttryck
                    tglr.on('click', toggleQuestion);
                    tglr.on('keydown', toggleQuestion);

                    // Lägg till tabindex för att göra elementen fokusbara
                    tglr.attr('tabindex', '0'); // Gör element fokusbara så de kan reagera på tangenttryckningar

                    $(document).on('click', 'a[href^="#"]', function (e) {
                        var id = $(this).attr('href');
                        var $id = $(id);
                        if ($id.length === 0) {
                            return;
                        } else {
                            e.preventDefault();
                            var pos = $(id).offset().top - 120;
                            $('body, html').animate({ scrollTop: pos });
                            $($id).addClass('scrolled_to');
                            setTimeout(function () {
                                $('.scrolled_to .paragraph_start').addClass("active");
                                $('.scrolled_to .hidden_content').addClass("active");
                                $('.scrolled_to .hidden_content').slideDown();
                            }, 200);
                        }
                    });

                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },

        //*********************************************************************
        // Kör script på kontaktsidan.
        //*********************************************************************
        'driftinformation': {
            init: function () {
                var map;
                var driftData = 'https://ftp.mirakel.nu/kristinehamn/';

                var page = $('.driftkartan');

                if (!map && page.find('#driftkarta').length) {
                    map = createMap();
                }

                //map = createMap();

                // skapa karta (element måste vara synligt när kartan skapas)
                //
                // parameterobjektet kan innehålla följande properties:
                //  target - element där karta ska visas (måste anges)
                //  utilities[] - array med begränsning till vissa nyttigheter, t.ex. ['el','fjv','fiber','va']. Default är att ingen begränsning görs
                //  url - adress till mapp för filer från Mirakel Avisering. Default="mirakel", dvs en relativ sökväg till en undermapp
                //  autoRefresh - sekunder för automatisk refresh av kartan. Default=0, dvs ingen refresh
                function createMap() {
                    return new mirakel.drift.GoogleMap({
                        target: '#driftkarta',
                        url: driftData,
                        autoRefresh: 10,
                        version: 3
                    });
                }

                // nyheter på startsida och driftsida
                //
                // parameterobjektet kan innehålla följande properties:
                //  target - element där nyheter ska visas (måste anges)
                //  utilities[] - array med begränsning till vissa nyttigheter, t.ex. ['el','fjv','fiber','va']. Default är att ingen begränsning görs
                //  url - adress till mapp för filer från Mirakel Avisering. Default="mirakel", dvs en relativ sökväg till en undermapp
                //  limit - begränsning till ett visst antal nyheter, t.ex. 3. Default är ingen begränsning.
                //  autoRefresh - sekunder för automatisk refresh av nyheter. Default=0, dvs ingen refresh
                new mirakel.drift.News({
                    target: '#newsfeed',
                    url: driftData,
                    limit: 3,
                    autoRefresh: 10,
                    version: 3
                });

                // (function($){
                //     function new_map($el) {
                //         //var $markers = $el.find('.marker');

                //         var args = {
                //             zoom        : 16,
                //             center      : new google.maps.LatLng(0, 0),
                //             mapTypeId   : google.maps.MapTypeId.ROADMAP
                //         };

                //         var map = new google.maps.Map($el[0], args);

                //         // map.markers = [];
                //         //
                //         // $markers.each(function(){
                //         //     add_marker($(this), map);
                //         // });
                //         //
                //         // center_map(map);

                //         return map;
                //     }

                // function add_marker($marker, map) {
                //     var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
                //
                //     var marker = new google.maps.Marker({
                //         position : latlng,
                //         map          : map
                //     });
                //
                //     map.markers.push(marker);
                //
                //     if($marker.html()){
                //         var infowindow = new google.maps.InfoWindow({
                //             content : $marker.html()
                //         });
                //
                //         google.maps.event.addListener(marker, 'click', function() {
                //             infowindow.open(map, marker);
                //         });
                //     }
                // }
                //
                // function center_map(map) {
                //     var bounds = new google.maps.LatLngBounds();
                //
                //     $.each( map.markers, function( i, marker ){
                //         var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                //         bounds.extend( latlng );
                //     });
                //
                //     if(map.markers.length == 1){
                //         map.setCenter(bounds.getCenter());
                //         map.setZoom(16);
                //     }else{
                //         map.fitBounds(bounds);
                //     }
                // }

                // var map = null;

                // $(document).ready(function(){
                //     $('#driftkarta').each(function(){
                //         map = new_map( $(this) );
                //     });
                // });

                // })(jQuery);

            }
        },

        //*********************************************************************
        // Tillgänglighetsredogörelse
        //*********************************************************************
        'tillganglighetsredogorelse': {
            init: function () {
                $('#feedback_form').validate({
                    rules: {
                        fornamn: {
                            required: true,
                        },
                        efternamn: {
                            required: true,
                        },
                        email: {
                            required: true,
                            email: true,
                        },
                        gdpr: {
                            required: true,
                        },
                        msg: {
                            required: true,
                        },
                    },
                    messages: {
                        fornamn: 'Fyll i ditt förnamn',
                        efternamn: 'Fyll i ditt efternamn',
                        email: 'Fyll i din e-post',
                        gdpr: 'Du måste godkänna villkoren',
                        msg: 'Fyll i ett meddelande',
                    }
                });
            },
        },

        //*********************************************************************
        // Kör script på ALLA sidor.
        //*********************************************************************
        'common': {
            init: function () {
                $(document).ready(function () {
                    const body = document.querySelector('body');
                    const header = document.querySelector('#header');
                    const uptimeInfoCont = document.querySelector('.priser_storningar');

                    if (body && header && !body.classList.contains('checkout')) {
                        let height = header.offsetHeight;

                        if (uptimeInfoCont && window.innerWidth <= 1140) { //mobile
                            height += uptimeInfoCont.offsetHeight;
                            height += 5; // add 5px margin on top for better style in dropdown menu
                        }

                        height = height - 1; // quickfix because there is 1px space between price and red border for some reason 

                        body.style.marginTop = `${height}px`;

                        window.addEventListener('onresize', () => {
                            let height = header.offsetHeight;

                            if (uptimeInfoCont && window.innerWidth <= 1140) {
                                height += uptimeInfoCont.offsetHeight;
                            }

                            body.style.marginTop = `${height}px`;
                        });
                    }

                    // Fokus på skip to content
                    $(".skip-to-content").click(function() {
                        $('#skip').focus();
                    });

                    // VISA SUB-MENU I MENYN
                    $('.nav > li.menu-item-has-children').hover(
                        function () { $(this).children('.sub-menu').addClass('open') },
                        function () { $(this).children('.sub-menu').removeClass('open') }
                    );

                    // Lägg till span.grow på undermenyer för lättare toggle.
                    $('<span class="grow"></span>').insertAfter('.nav li.menu-item-has-children ul.sub-menu li.menu-item-has-children > a');
                    $('<span class="grow"></span>').insertAfter('.sidenav li.menu-item-has-children ul.sub-menu li.menu-item-has-children > a');
                    $("li.menu-item-has-children span.grow").on('click', function () {
                        if ($(this).hasClass('active')) {
                            $(this).removeClass('active');
                            $(this).parent().children("ul").slideUp('fast').removeClass('active');
                        } else {
                            $(".nav li ul.sub-menu li ul.sub-menu").slideUp('fast');
                            $('li.menu-item-has-children span.grow').removeClass('active');
                            $(this).parent().children("ul").slideDown('fast').addClass('active');
                            $(this).addClass('active');
                        }
                    });
                    //Om skärmen är mindre eller lika 1036px
                    if ($(window).width() <= 1036) {
                        //Lägg till knappen GROW för togglefunktion
                        $('<span class="grow_mobile"></span>').insertAfter('.nav li.menu-item-has-children > a');
                        $("li.menu-item-has-children span.grow_mobile").on('click', function () {
                            $(this).toggleClass('active');
                            $(this).parent().children("ul").slideToggle('fast').toggleClass('active');
                        });
                        $('#navigation').insertAfter('#header');
                    }

                    // VISA OCH DÖLJ MOBILMENY
                    $('#toggle_navigation').on('click', function () {
                        $(this).toggleClass('open');
                        $("html, body").animate({ scrollTop: "0px" });
                        $('#menu-huvudnavigation').slideToggle(400);
                    });

                    // TOGGLE SEARCH
                    $('.hs_toggle').on('click', function () {
                        $('.hs_holder').toggleClass('open');
                    });

                    // FÖRMINSKA SIDHUVUD EFTER 100PX
                    $(window).scroll(function () {
                        if ($(this).scrollTop() > 100) {
                            $('#header').addClass('smaller');
                            $('.priser_storningar').addClass('smaller');
                        } else {
                            $('#header').removeClass('smaller');
                            $('.priser_storningar').removeClass('smaller');
                        }
                    });

                    // DÖLJ MESSAGE NOTICE
                    var cont = $('.full-size-notice');
                    cont.on('click', function () {
                        $(this).slideToggle(300);
                    });

                    // TOGGLA SIDEBAR RESPONSIVT
                    var sbt = $('.sidebar_toggle');
                    var sb = $('#sidebar');
                    sbt.on('click', function () {
                        sb.slideToggle(300);
                    });

                    // FORMULÄR IKONER
                    $(".row input, .row select, .row textarea").blur(function () {
                        var $this = $(this);
                        var parent = $this.parent();
                        if (($(this).val().trim().length != 0)) {
                            $this.addClass('valid');
                            $this.removeClass('error');
                        } else {
                            $this.addClass('error');
                            $this.removeClass('valid');
                        }
                    });

                    // Placera sidfoten i botten på alla sidor om utrymme uppåt finns.
                    var docHeight = $(window).height();
                    var footerHeight = $('#footer').height();
                    var footerTop = $('#footer').position().top + footerHeight;
                    if (footerTop < docHeight) {
                        $('#footer').css('margin-top', (docHeight - footerTop) + 'px');
                    }

                    // Ta bort data-src när bilden är laddad.
                    [].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
                        img.setAttribute('src', img.getAttribute('data-src'));
                        img.onload = function () {
                            img.removeAttribute('data-src');
                        };
                    });

                    // Spåra Utklick (GA)
                    function _gaLt(event) {
                        var el = event.srcElement || event.target;
                        while (el && (typeof el.tagName == 'undefined' || el.tagName.toLowerCase() != 'a' || !el.href)) {
                            el = el.parentNode;
                        }
                        if (el && el.href) {
                            var link = el.href;
                            if (link.indexOf(location.host) == -1 && !link.match(/^javascript\:/i)) {
                                var hitBack = function (link, target) {
                                    target ? window.open(link, target) : window.location.href = link;
                                };
                                var target = (el.target && !el.target.match(/^_(self|parent|top)$/i)) ? el.target : false;
                                ga("send", "event", "Outgoing Links", link,
                                    document.location.pathname + document.location.search,
                                    { "hitCallback": hitBack(link, target) });
                                event.preventDefault ? event.preventDefault() : event.returnValue = !1;
                            }
                        }
                    }
                    var w = window;
                    w.addEventListener ? w.addEventListener("load", function () { document.body.addEventListener("click", _gaLt, !1) }, !1)
                        : w.attachEvent && w.attachEvent("onload", function () { document.body.attachEvent("onclick", _gaLt) });

                }); //End of $(document).ready(function()
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            UTIL.fire('common');
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            UTIL.fire('common', 'finalize');
        }
    };

    $(document).ready(UTIL.loadEvents);

})(jQuery);
